.concede-button {
  margin: 0 auto;
  display: block;
  /* transform: translateY(6px); */
  border: none;
  width: 200px;
  height: 50px;
  background-color: rgb(247, 194, 145);
  color: #5a342e;
  cursor: pointer;
  font-size: 15px;
  font-family: "Barlow", cursive;
}
.concede-button:active,
.concede-button:hover {
  background-color: rgb(248, 203, 161);
}
.concede-button-link {
  text-decoration: none;
}

.disable-concede {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
