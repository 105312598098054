
.container {
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: relative;
}

.app-title {
  margin: 1.5rem;
  padding: 1rem;

  z-index: 1;
  text-align: center;
  color: #8b4f42c5;
  border-radius: 2.5rem;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 2;
  font-size: 2.2rem;

  cursor: pointer;
  font-family: "Crimson Text", sans-serif;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bg-img {
  height: 100vh;
  flex: 0.5;
  width: 50vw;
}

.content {
  flex: 0.5;
  padding: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Grandstander", cursive;
}
