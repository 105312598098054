html,
body {
  margin: 0;
  padding: 0;
}
html,
body,
#app,
#app > div,
#root {
  height: 100%;
}

.description-container {
  /* position: absolute; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 540px;
  height: 340px;
}
.description {
  display: flex;
  flex-direction: column;

  align-items: center;
  z-index: 2;
  gap: 10px;
  font-family: "Crimson Text", serif;
}
.description p {
  font-size: 16px;
  margin-bottom: 10px;
}
.play-again {
  position: relative;
  bottom: 0px;
  border: 1px solid #a35b4a;
  padding: 6px;
  color: #2b7b8f;
  border-radius: 1px;
  background-color: #eef8f9;
  cursor: pointer;
  font-family: "Barlow", sans-serif;
  z-index: 10;
  transform: translateY(-3px);
}
.chess-img {
  width: 32%;
  transform: translateY(-10px);
}

.game-end-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-image: url("../../assets/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.winner {
  margin-top: -10px;
}
.position-play-again {
  margin-top: -10px;
  position: relative;
  bottom: 0px;
  border: 1px solid #a35b4a;
  padding: 6px;
  color: #2b7b8f;
  border-radius: 1px;
  background-color: #eef8f9;
  cursor: pointer;
  font-family: "Barlow", sans-serif;
  z-index: 10;
  transform: translateY(-4px);
}
.position-chess-img {
  width: 32%;
  transform: translateY(-13px);
}
/* Not Used  */
/* ------------------------------------------------------------------ */
