.piece {
  width: 70%;
  height: 70%;
}
.inactive {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}
.active {
}
