html,
body {
  margin: 0;
}

.game {
  margin: 0;
  height: 1200px;
  background-image: url(../../assets/game-background.jpg);
  background-size: cover;
}

.board {
  margin-top: 50px;
}
.player {
  margin-top: 75px;
}
.reload-tip {
  transform: translateY(12px);
  margin: auto;
  display: block;
  width: fit-content;
  color: #a35b4a;
  font-size: 15px;
}
.link-tip {
  transform: translateY(19px);
  margin: auto;
  display: block;
  width: fit-content;
  color: #a35b4a;
  font-size: 15px;
}
.castle-tip {
  transform: translateY(26px);
  margin: auto;
  display: block;
  width: fit-content;
  color: #a35b4a;
  font-size: 15px;
}

.game-id {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  color: #5a342e;
  font-size: 15px;

  text-align: center;
  font-family: "Nanum Myeongjo";

  height: 40px;
  background-color: rgb(247, 194, 145);
}
.game-id-container {
  /* transform: translateY(40px); */
  transform: translateY(35px);
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100px;
  height: 120px; */
  gap: 8px;
  text-align: center;
  flex-direction: column;
  margin: auto;
}
.game-id-title {
  font-family: "Crimson Text";

  color: #834132;
}

@media screen and (max-height: 730px) {
  .game {
    height: 1400px;
  }
}
